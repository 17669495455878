// Here you can add other styles
.email-app {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    background: #fff;
    border: 1px solid #c8ced3;

    nav {
        -ms-flex: 0 0 200px;
        flex: 0 0 200px;
        padding: 1rem;
        border-right: 1px solid #c8ced3;
    }
    main {
        -ms-flex: 1;
        flex: 1;
        min-width: 0;
        padding: 1rem;
    }
}

@media (max-width: 767.98px) {
    .email-app {
        nav {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        }
    }
}